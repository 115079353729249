import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import React from 'react';
import data from '../../assets/horo-data/horo-tips.json';
import { useStyles } from './Styles';

export default function HoroTips() {
    const classes = useStyles();
    return (
        <Container maxWidth={'sm'}>
            <div className={classes.pageHeader}>
                <div>
                    เคล็ดลับจากฟากฟ้า.......ปลุกพลังพฤหัสย้ายเข้าราศีมีน (ท้องฟ้าสากล)
                </div>
                <div>
                    พฤหัส โคจรย้ายเข้าราศีมีน มีผลตั้งแต่ วันที่ 14 พฤษภาคม 2564 เป็นต้นไป
                </div>
                <div>
                    โชคใน 1 ปีสำหรับทุกคนจะเปลี่ยนไปจากปีที่ผ่านมา มาปลุกพลังเพิ่มโชคลาภกัน….
                </div>
                <div>
                    หากอยากได้พลังของพฤหัสแบบเต็มๆ ทำตามนี้เลยครับ
                </div>
            </div>
            <div className={classes.divider} />
            {
                data.map((c: any) => (
                    <>

                        <div className={classes.contentHeader}>
                            {c.header}
                        </div>
                        <div className={classes.subDivider} />
                        {
                            c.contents.map((content: string) => (
                                <div className={classes.content}>
                                    {content}
                                </div>
                            ))
                            
                        }
                       
                       <div className={classes.divider} />
                    </>
                ))
            }
        </Container>
    );
}