import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import Daily from './Daily';
import Monthly from './Monthly';
import Tips from './Tips';
const menus = [
    {
        label: 'ดวงประจำปี 2564',
        page: 'home'
    },
    {
        label: 'ดวงรายเดือน',
        page: 'monthly'
    },
    {
        label: 'เสริมดวง',
        page: 'tips'
    }
]

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarHeader: {
        width: `calc(100% - 100px)`,
        textAlign: 'center'
    },
    appBarHeaderText1: {
        fontWeight: 'bold',
        fontSize: '20px'
    },
    appBarHeaderText2: {
        fontSize: '16px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export default function PersistentDrawerLeft() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState('home');
    const getPage = React.useCallback(() => {
        switch (page) {
            case 'tips':
                return <Tips />;
            case 'monthly':
                return <Monthly />;
            default:
                return <Daily />;
        }
    }, [page]);

    // const [page, setPage] = React.useState(Daily);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Container className={classes.root} maxWidth={'sm'}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.appBarHeader} variant="h6" noWrap>
                        <div className={classes.appBarHeaderText1}>Horazap</div>
                        <div className={classes.appBarHeaderText2} >เราดี ดีกว่า ดวงดี</div>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="top"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {menus.map((menu, index) => (
                        <ListItem button key={`menu-${index}`}>
                            <ListItemText primary={menu.label} onClick={
                                () => {
                                    setPage(menu.page);
                                    setOpen(false);
                                    window.scrollTo(0, 0);
                                }
                            } />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {
                    getPage()
                }
            </main>
        </Container>
    );
}
