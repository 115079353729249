import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Wap1Step from '../components/Wap1Step';
import { getLandingPageInfo } from '../services/landing-page-service'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      boxShadow: 'none'
    },
    error: {
      color: 'red'

    },
  }),
);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Wap1StepContainer() {
  const classes = useStyles();
  const query = useQuery();
  const sid = query.get('sid');
  const theme = query.get('theme') ? query.get('theme') : 1;
  let header, banner;
  try {
    header = require(`assets/images/services/${sid}/themes/${theme}/header.jpg`);
  } catch (error) {

  }
  try {
    banner = require(`assets/images/services/${sid}/themes/${theme}/banner.jpg`);
  } catch (error) {
  }

  const [member, setMember] = useState(0);
  const [footer, setFooter] = useState('');
  const [ivr, setIvr] = useState('');
  const [loading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const serviceApi = `${window.location.origin}/datas/service.json?time=${Date.now()}`;

  useEffect(() => {
    (async () => {
      if (isMounted) {
        setLoading(true);
      }
      try {

        if (sid && /^\d+$/.test(sid)) {
          const service = await getLandingPageInfo(parseInt(sid), serviceApi);
          if (isMounted) {
            if (service) {
              setMember(service.member);
              setFooter(service.footer);
              setIvr(service.ivr);
            }
          }
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setLoading(false);
        }
      }
    })();
    return () => {
      setIsMounted(false);
    };
  }, [sid, serviceApi]);

  if (loading) {
    return (
      <Container maxWidth={'sm'}>
        <Grid container justify={'center'}>
          <CircularProgress />
        </Grid>
      </Container>
    )
  }

  if (!ivr || !header || !banner) {
    return (
      <div className={classes.root}>
        <Grid className={classes.error} container spacing={0} justify={'center'}>Service not found</Grid>
      </div>
    );
  }
  
  return (
    <Wap1Step
      banner={banner}
      header={header}
      footer={footer}
      wording={`จำนวนผู้เข้าไปอ่านล่าสุด ${member} คน`}
      ivr={ivr}
    />
  );
}