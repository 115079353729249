import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    content: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0
      }
    },
    error: {
      color: 'red'

    },
    headerImage: {
      width: '100%',
      height: 'auto'
    },
    wording1: {
      // paddingBottom: '20px',
      color: 'red'
    },
    bannerImage: {
      marginLeft: '5%',
      width: '90%',
      height: 'auto'
    },
    footer: {
      // marginTop: 'calc(5% + 60px)',
      // bottom: 0,
      backgroundColor: '#e9e9e9',
      borderColor: '#ddd',
      fontWeight: 700,
      fontSize: '14px',
      textAlign: 'center'
      // padding: theme.spacing(1, 1),
    },
    closeIcon: {
      fontSize: '10px',
    },
    closeIconContainer: {
      position: 'absolute',
      right: '0px'
    },
    botton: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      fontSize: '30px',
      fontWeight: 'bolder',
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px',
      marginBottom: '10px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      width: '100%'
    }
  }),
);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Wap2() {
  const classes = useStyles();
  const query = useQuery();
  const sid = query.get('sid');
  const theme = query.get('theme') ? query.get('theme') : 1;
  const confirm = query.get('c');
  const all = query.get('all');
  let header, banner, banner2;
  try {
    header = require(`assets/images/services2/${sid}/themes/${theme}/header.jpg`);
  } catch (error) {

  }
  try {
    banner = require(`assets/images/services2/${sid}/themes/${theme}/banner.jpg`);
  } catch (error) {
  }
  try {
    banner2 = require(`assets/images/services2/${sid}/themes/${theme}/banner-2.jpg`);
  } catch (error) {
  }

  const [member, setMember] = useState(0);
  const [step, setStep] = useState(1);
  const [footer, setFooter] = useState('');
  const [ivr, setIvr] = useState('');
  const [loading, setLoading] = useState(true);
  const serviceApi = `${window.location.origin}/datas/service.json?time=${Date.now()}`;
  const disabledServices = ['47'];
  useEffect(() => {
    const fetchData = async () => {
      if (sid && /^\d+$/.test(sid)) {
        const result = await axios.get(serviceApi);
        if (result) {
          const service = result.data.find((s: any) => (s.id === parseInt(sid)));
          setMember(service.member);
          setFooter(service.footer);
          setIvr(service.ivr);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [sid, serviceApi]);

  if (loading) {
    return (
      <Container maxWidth={'sm'}>
        <Grid container justify={'center'}>
          <CircularProgress />
        </Grid>
      </Container>
    )
  }

  if ((sid && disabledServices.includes(sid) && !all) || !header || !banner) {
    return (
      <div className={classes.root}>
        <Grid className={classes.error} container spacing={0} justify={'center'}>Service not found</Grid>
      </div>
    );
  }

  let bannerComponent;
  let button;
  if (confirm) {
    if (step === 1) {
      if (banner2) {
        bannerComponent = (<CardMedia
          style={{
            cursor: 'pointer'
          }}
          className={classes.bannerImage}
          image={banner}
          component={'img'}
          onClick={() => {
            setStep(2);
          }} />);
      } else {
        bannerComponent = (<CardMedia className={classes.bannerImage} component={'img'} image={banner} />);
        button = (
          <Button href={`tel:*${ivr}`} variant="contained" className={classes.botton}>
            สมัครบริการ
          </Button>);
      }
    } else {
      bannerComponent = (<CardMedia className={classes.bannerImage} component={'img'} image={banner2} />);
      button = (
        <Button href={`tel:*${ivr}`} variant="contained" className={classes.botton}>
          สมัครบริการ
        </Button>
      );
    }
  } else {
    if (banner2) {
      bannerComponent = (<CardMedia className={classes.bannerImage} component={'img'} image={banner2} />);
      button = (
        <Button href={`tel:*${ivr}`} variant="contained" className={classes.botton}>
          สมัครบริการ
        </Button>
      );
    } else {
      bannerComponent = (<CardMedia className={classes.bannerImage} component={'img'} image={banner} />);
      button = (
        <Button href={`tel:*${ivr}`} variant="contained" className={classes.botton}>
          สมัครบริการ
        </Button>
      );
    }
  }

  return (
    <Container maxWidth={'sm'}>
      <div className={classes.closeIconContainer} onClick={() => { window.close(); }}><HighlightOffIcon className={classes.closeIcon} /></div>
      <Card className={classes.root}>
        <CardMedia className={classes.headerImage} component={'img'} image={header} />
        <CardContent className={classes.content} >
          <Typography className={classes.wording1} variant={'body1'} component="p" align={'center'}>
            จำนวนผู้เข้าไปอ่านล่าสุด {member} คน
          </Typography>
          {bannerComponent}
          {button}
          <Typography className={classes.footer} variant={'body2'}>{footer}</Typography>
        </CardContent>
      </Card>
    </Container>
  );
}