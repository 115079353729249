import Container from '@material-ui/core/Container';
import React from 'react';
import data from '../../assets/horo-data/horo-month.json';
import { useStyles } from './Styles';

export default function HoroMonth() {
    const classes = useStyles();
    return (
        <Container className={classes.root}  maxWidth={'sm'}>
            <div className={classes.pageHeader}>{'เดือนไหน ราศีไหนดวงแรง โชคลาภ การงาน ความรักเด่น'}</div>
            <div className={classes.divider} />
            {
                data.map((content: any) => (
                    <>
                        <div className={classes.contentHeader}>{content.header}</div>
                        <div className={classes.subDivider} />
                        {content.contents.map((c: any) => (
                            <>
                                <div className={classes.contentSubHeader}>{c.header}</div>
                                <div className={classes.content}>{c.content}</div>
                                <div className={classes.subDivider} />
                            </>
                        ))
                        }
                        <div className={classes.divider} />
                    </>
                ))
            }
        </Container>
    );
}