import React, { MouseEventHandler, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            boxShadow: 'none',
            textAlign: 'center',
            maxWidth: '600px',
        },
        closeBtnContainer: {
        },
        closeIcon: {
            fontSize: '25px',
            color: 'white',
            zIndex: 1,
            cursor: 'pointer'
        },
        header: {
            marginTop: '-25px'

        },
        wording: {
            color: 'red'
        },
        footerContainer: {
            backgroundColor: '#e9e9e9',
            borderColor: '#ddd',
            textAlign: 'center'

        },
        footer: {
            fontWeight: 700,
            fontSize: '0.8rem ',
        },
        buttonContainer: {
            marginTop: '-150px',
            minHeight: '150px',
            textAlign: 'center',

            '@media (min-width: 0px) and (max-width: 320px)': {
                marginTop: '-90px',
                minHeight: '90px',
            },

            '@media (min-width: 321px) and (max-width: 375px)': {
                marginTop: '-115px',
                minHeight: '115px',
            },

            '@media (min-width: 376px) and (max-width: 438px)': {
                marginTop: '-130px',
                minHeight: '130px',
            },
        },
        button: {
            background: '#ab1005',
            color: '#FFF',
            fontWeight: 'bold',
            '&:hover': {
                fontSize: 32,
                background: '#ab1005',
            },
            fontSize: 30,
            height: 60,
            width: 150,
            borderRadius: 20,
            marginLeft: 30,
            marginRight: 30,
            '@media (min-width: 0px) and (max-width: 320px)': {
                fontSize: 16,
                height: 40,
                width: 100,
                borderRadius: 10,
                marginLeft: 10,
                marginRight: 10,
                '&:hover': {
                    fontSize: 18,
                },
            },

            '@media (min-width: 321px) and (max-width: 375px)': {
                fontSize: 18,
                height: 50,
                width: 120,
                borderRadius: 15,
                marginLeft: 15,
                marginRight: 15,
                '&:hover': {
                    fontSize: 20,
                },
            },

            '@media (min-width: 376px) and (max-width: 438px)': {
                fontSize: 20,
                height: 55,
                width: 120,
                borderRadius: 15,
                marginLeft: 15,
                marginRight: 15,
                '&:hover': {
                    fontSize: 22,
                },
            },
        }
    }),
);

export default function Wap2Steps(props: Props) {
    const classes = useStyles();
    const { banner1, banner2, ivr, header, wording, footer, handleRegister, handleCancel, handleClose } = props;
    const [step, setStep] = useState(1);

    return (
        <Container maxWidth={'sm'} className={classes.root} >
            <Grid container direction={'row'} justify="space-between" alignItems="center">
                <Grid
                    container
                    justify="flex-end"
                    className={classes.closeBtnContainer}>
                    <CloseIcon
                        onClick={() => {
                            window.open('', '_self');
                            window.close();
                        }}
                        className={classes.closeIcon} />
                </Grid>
                <Grid className={classes.header}><CardMedia component={'img'} image={header} /></Grid>
            </Grid>
            <Grid >
                <Typography className={classes.wording} variant={'body2'} component="p" align={'center'}>{wording}</Typography>
            </Grid>
            <Grid>
                {
                    step === 1 ?
                        (
                            <CardMedia onClick={() => {
                                setStep(2);
                            }} component={'img'} image={banner1} />) :
                        (
                            <>
                                <CardMedia component={'img'} image={banner2} />
                                <Container className={classes.buttonContainer}>
                                    <Button className={classes.button} href={`tel:*${ivr}`} variant="contained">ยืนยัน</Button>
                                    <Button className={classes.button} onClick={() => {
                                        window.open('', '_self');
                                        window.close();
                                    }} variant="contained">ยกเลิก</Button>
                                </Container>
                            </>
                        )
                }

            </Grid>
            <Grid className={classes.footerContainer}>
                <Typography className={classes.footer} variant={'body2'}>{footer}</Typography>
            </Grid>
        </Container>
    )
}
interface Props {
    banner1: string;
    banner2: string;
    header: string;
    footer: string;
    wording: string;
    ivr?: string;
    handleRegister?: MouseEventHandler;
    handleCancel?: MouseEventHandler;
    handleClose?: MouseEventHandler;
}