import axios from 'axios';

/**
 * 
 * @param {number} serviceId 
 * @param {string} serviceApi 
 * @returns {}
 */
export async function getLandingPageInfo(serviceId, serviceApi) {
    const disabledServices = ['47'];
    const result = await axios.get(serviceApi);
    if (result) {
        return result.data.find((s) => (s.id === parseInt(serviceId)));
    }
}