import React, { MouseEventHandler, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            boxShadow: 'none',
            textAlign: 'center',
            maxWidth: '600px',
        },
        closeBtnContainer: {
        },
        closeIcon: {
            fontSize: '25px',
            color: 'white',
            zIndex: 1,
            cursor: 'pointer'
        },
        header: {
            marginTop: '-25px'

        },
        wording: {
            color: 'red'
        },
        footerContainer: {
            backgroundColor: '#e9e9e9',
            borderColor: '#ddd',
            textAlign: 'center'

        },
        footer: {
            fontWeight: 700,
            fontSize: '0.6rem ',
        },
    }),
);

export default function Wap1Step(props: Props) {
    const classes = useStyles();
    const { banner, ivr, header, wording, footer } = props;

    return (
        <Container maxWidth={'sm'} className={classes.root} >
            <Grid container direction={'row'} justify="space-between" alignItems="center">
                <Grid
                    container
                    justify="flex-end"
                    className={classes.closeBtnContainer}>
                    <CloseIcon
                        onClick={() => {
                            window.open('', '_self');
                            window.close();
                        }}
                        className={classes.closeIcon} />
                </Grid>
                <Grid className={classes.header}><CardMedia component={'img'} image={header} /></Grid>
            </Grid>
            <Grid >
                <Typography className={classes.wording} variant={'body2'} component="p" align={'center'}>{wording}</Typography>
            </Grid>
            <Grid>
                <a href={`tel:*${ivr}`}>
                    <CardMedia component={'img'} image={banner} />
                </a>

            </Grid>
            <Grid className={classes.footerContainer}>
                <Typography className={classes.footer} variant={'body2'}>{footer}</Typography>
            </Grid>
        </Container>
    )
}
interface Props {
    banner: string;
    header: string;
    footer: string;
    wording: string;
    ivr?: string;
    handleRegister?: MouseEventHandler;
    handleCancel?: MouseEventHandler;
    handleClose?: MouseEventHandler;
}