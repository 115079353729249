import Container from '@material-ui/core/Container';
import React from 'react';
import data from '../../assets/horo-data/home.json';
import { useStyles } from './Styles';

export default function Daily() {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth={'sm'}>
            <div className={classes.pageHeader}>{'ดวงของท่านในปี 2564 พร้อมเคล็ดลับ ในการเสริมดวง'}</div>
            <div className={classes.divider} />
            {
                data.map((content: any) => (
                    <>
                        <div className={classes.contentHeader}>{content.header}</div>
                        <div className={classes.subDivider} />
                        {
                            content.contents.map((cc: any) => (
                                <>
                                    <div className={classes.contentSubHeader}>{cc.header}</div>
                                    {
                                        cc.contents.map((ccc: any) => (

                                            <div className={classes.content}>{ccc}</div>
                                        ))
                                    }
                                    <div className={classes.subDivider} />
                                </>
                            ))
                        }
                        <div className={classes.divider} />
                    </>
                ))
            }
        </Container>
    );
}