import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Wap from './pages/Wap';
import Wap2StepsContainer from './pages/Wap2StepsContainer';
import Wap1StepContainer from './pages/Wap1StepContainer';
import Wap2 from './pages/Wap2';
import A2 from './pages/services/A2';
import Home from './pages/horo/Home';
import './App.css';

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history}>  
      <Switch>
        <Route path={'/wap'} component={Wap} />
        <Route path={'/wap-demo'} component={Wap2StepsContainer} />
        <Route path={'/wap-2-step'} component={Wap2StepsContainer} />
        <Route path={'/wapv2'} component={Wap1StepContainer} />
        <Route path={'/wap2'} component={Wap2} />
        <Route path={'/a2'} component={A2} />
        <Route path={'/'} component={Home} />
        <Redirect to={'/'} />
      </Switch>
    </Router>
  );
}

export default hot(App);
