import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import bgHeader from 'assets/images/special/a2/header.jpg';
import bgContent from 'assets/images/special/a2/bg.jpg';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
        },
        headerBanner: {
            backgroundImage: `url(${bgHeader})`,
            textAlign: 'center',
            color: '#FF0',
            width: '100%',
            textShadow: 'black 0.1em 0.1em 0.1em',
            fontSize: '2rem',
            fontFamily: 'KittiB'
        },
        content: {
            backgroundImage: `url(${bgContent})`,
            paddingBottom: '.5rem'
        },
        contentTitle: {
            color: '#ee222a',
            textAlign: 'center',
            fontSize: '1.5em',
            fontFamily: 'KittiB'
        },
        contentDetail: {
            paddingLeft: '.5rem',
            paddingRight: '.5rem'
        },
        contentParagraphContainer: {
            // paddingBottom: '.5rem'
        },
        contentParagraph: {
            fontFamily: 'KittiB',
            fontSize: '1.4rem'
        },
        closeIcon: {
            fontSize: '10px',
        },
        closeIconContainer: {
            position: 'absolute',
            right: '0px'
        },
        button: {
            fontSize: '1.5rem',
            lineHeight: '1.5px',
            textAlign: 'center',
            backgroundColor: '#fff200',
            border: '#e0d500',
            '&:hover': {
                //you want this to be the same as the backgroundColor above
                backgroundColor: "#fff200"
            },
            width: '100%',
        },
        buttonContainer: {
            width:'100%',
            paddingBottom: '1rem'
        },
        buttonText1: {
            fontFamily: 'KittiB',
            fontSize: '1.5rem',
        },
        buttonText1Container: {
            paddingBottom: '.5rem'
        },
        buttonText2: {
            color: '#ee222a'
        },

    }),
);


export default function A2() {
    const classes = useStyles();
    const subButton = (
        <Button
            className={classes.button}
            onClick={() => {
                window.location.href = '/wap/lp?sid=56&theme=1';
            }}
        >
            <Grid alignItems={'center'}>
                <Typography component={'div'} className={classes.buttonText1}>
                    ต้องการอ่านดวงการเงินโชคลาภ 12 ราศี
                </Typography>
                <Typography component={'div'} className={classes.buttonText1}>
                    ปี 2564 <span className={classes.buttonText2}>กด</span>
                </Typography>
            </Grid>
        </Button>
    );
    return (
        <Container maxWidth={'sm'}>
            <div className={classes.closeIconContainer} onClick={() => {
                window.close();
            }}><HighlightOffIcon className={classes.closeIcon} /></div>
            <Card className={classes.root}>
                <Typography
                    className={classes.headerBanner}
                >
                    ราศีเด่น ประจำเดือน
                </Typography>
                <Card className={classes.content}>
                    <Typography className={classes.contentTitle}>มกราคม 2564</Typography>
                    <Grid className={classes.contentDetail}>
                        <Grid className={classes.contentParagraphContainer}>
                            <Typography className={classes.contentParagraph}>
                                1. ราศีเมษ (ท่านที่เกิดระหว่างวันที่ 21 มีนาคมถึง 20 เมษายน)
                            </Typography>
                            <Typography className={classes.contentParagraph}>
                                ดวงการงานเด่นอย่างเห็นได้ชัด ได้รับเกียรติ ได้รับการยอมรับนับถือ
                                การเจรจาวิ่งเต้นติดต่อเข้ามาเพียบ รายได้พิเศษที่ท่า ท่าจะเครียด
                                ก็ยังมีผู้ใหญ่ให้ความเมตตาช่วยเหลือ การติดต่อกับต่างประเทศ แดนไกล ยังรื่นไหลไม่ติดขัด
                                จะได้ร่วมงานกับ คนมีเสน่ห์เป็นที่ถูกใจ
                            </Typography>
                        </Grid>
                        <Grid className={classes.buttonContainer}>
                            {subButton}
                        </Grid>

                        <Grid className={classes.contentParagraphContainer}>
                            <Typography className={classes.contentParagraph}>
                                2. ราศีกรกฎ (ท่านที่เกิดระหว่างวันที่ 21 มิถุนายนถึง 22 กรกฎาคม)
                            </Typography>
                            <Typography className={classes.contentParagraph}>
                                ดวงคู่ครองความรักมีสีสันมีโอกาสในการติดต่อเจรจามากขึ้น คิดถึงการเปลี่ยนแปลงในเรื่องรัก ๆ
                                ใคร่ ๆ แต่ยังต้องอดทน อดกลั้นกับความไม่ชอบใจบางอย่าง การงานยังคึกคักโดยเฉพาะในสัปดาห์แรก
                                หลังจากนั้นจะไปทุ่มเทกับงานเสริม งานอดิเรก หากมีการป่วยไข้ จะมีโอกาสได้หมอดี
                                ยาดีมารักษาทำให้อาการลดความรุนแรง
                            </Typography>
                        </Grid>
                        <Grid className={classes.buttonContainer}>
                            {subButton}
                        </Grid>

                        <Grid className={classes.contentParagraphContainer}>
                            <Typography className={classes.contentParagraph}>
                                3. ราศีตุล (ท่านที่เกิดระหว่างวันที่ 24 กันยายนถึง 23 ตุลาคม)
                            </Typography>
                            <Typography className={classes.contentParagraph}>
                                โอกาสในด้านการหารายได้ยังขยายตัวได้ดี ชีวิตรัก ชีวิตคู่ อดมีเรื่องกระทบกระทั่งกันไม่ได้
                                ต้องลดดีกรีความห้าวลงบ้าง หากทำงานใกล้ชิดกับผู้ใหญ่ต้องอ่อนน้อมถ่อมตน ยอมอ่อนข้อไว้ก่อน
                                จะได้รับความเมตตา ได้รับการสนับสนุนช่วยเหลือ
                                กิจกรรมด้านการเสี่ยงดวงทั้งหลายจะสมหวังก็ต่อเมื่อกระทำการแบบช้าๆ ไม่รีบร้อน
                                มีโชคจากคนสูงอายุ
                            </Typography>
                        </Grid>
                        <Grid className={classes.buttonContainer}>
                            {subButton}
                        </Grid>

                        <Grid className={classes.contentParagraphContainer}>
                            <Typography className={classes.contentParagraph}>
                                4. ราศีมังกร (ท่านที่เกิดระหว่างวันที่ 22 ธันวาคมถึง 21 มกราคม)
                            </Typography>
                            <Typography className={classes.contentParagraph}>
                                คนรอบข้างให้ความเคารพ ยอมรับนับถือมากขึ้น มีการเจรจาถึงสิ่งใหม่ ๆ พร้อมปรับตัว
                                พัฒนาตนเองให้ดีขึ้น ดวงดีมีโชค ทางการเงินจากอสังหาริมทรัพย์การงาน
                                ธุรกิจมีแนวโน้มสำเร็จตามเป้าหมายที่วางไว้ รายได้เสริมเด่น มาจากงานวิชาการ
                            </Typography>
                        </Grid>
                        <Grid className={classes.buttonContainer}>
                            {subButton}
                        </Grid>
                    </Grid>
                </Card>
            </Card>
        </Container>
    );
}
