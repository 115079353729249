import { makeStyles, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
        },
        pageHeader: {
            textAlign: 'center',
            fontSize:'16px',
            color: 'white',
            background: 'red',
        },
        contentHeader: {
            fontSize:'14px',
            color: 'blue'
        },
        contentSubHeader: {
            fontSize:'14px',
            color: 'gray',
            textDecorationLine: 'underline',
            fontWeight: 'bold'
        },
        content: {
            fontSize: '12px'
        },
        divider: {
            marginBottom: '20px'
        },
        subDivider: {
            marginBottom: '5px'
        }
    }),
);